<template>
  <div>
    <h4>Data implementation</h4>
    <hr/>

    <b-tabs content-class="mt-3" lazy>
      <b-tab active class="wrapper" title="Search">
        <data-implementation-search/>
      </b-tab>
      <b-tab title="Editor" v-if="isAdmin">
        <data-implementation-editor/>
      </b-tab>
      <b-tab title="Graph">
        <data-object-graph/>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import DataImplementationEditor from "@/components/DataImplementationEditor";
import DataImplementationSearch from "@/components/DataImplementationSearch";
import DataObjectGraph from "@/components/DataObjectGraph.vue";
import AuthMixin from '@/mixins/auth'
export default {
  mixins:[AuthMixin],
  components: {DataImplementationEditor, DataImplementationSearch, DataObjectGraph},
  beforeDestroy() {
    this.$store.commit('dataObject/setSources', [])
    this.$store.commit('dataObject/setDataObjectAttributes', undefined)
    this.$store.commit('dataObject/setDataObject', undefined)
    this.$store.commit('dataObject/setImplementations', [])
  }
};
</script>
<style scoped>
.wrapper {
  overflow-y: auto;
  height: calc(100vh - 170px);
}
</style>

<template>
  <div>
    <h4>Visual relations graph
      <b-button id="popover-button-sync" size="sm">
        <magnify/>
      </b-button>
    </h4>
    <div class="legend-wrapper">
      <div v-for="(color, data_partner) in colorMapping" :key="data_partner">
        <div :style="{'background-color':color}" class="group-legend float-left mt-1 mr-2"></div>
        <span class="float-left">{{ data_partner }}</span>
        <br/>
      </div>
    </div>
    <b-popover target="popover-button-sync" title="Search object">
      <b-input v-model="search" type="search"/>
    </b-popover>
    <b-modal id="object-graph-modal" hide-footer title="Object details">
      <kv-table :kvs="dataObjectProperties"/>
    </b-modal>
    <p v-if="loading" class="text-center mt-5">
      <b-spinner label="Large Spinner" style="width: 3rem; height: 3rem;"></b-spinner>
    </p>
    <div id="graph"></div>
    <foreignObject v-for="node in nodes" :key="`fo-${node.id}`" :class="`fo-${node.id}`"
                   height="33" width="300" x="0" y="0">
      <div :class="{'selected-data-object': match(node.name)}"
           :style="{'background-color': colorMapping[node.data_partner]}"
           class="data-object pl-2">

        <p class="float-left pt-1">{{ node.name }}</p>
        <b-button class="float-right" size="sm" @click="modal(node)">
          <view-list/>
        </b-button>
      </div>
    </foreignObject>
    <div class="link-info text-center pt-3" @mouseleave="moveInfo(0, 0, 0)">{{
        currentNoteLabel
      }}
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import Magnify from 'vue-material-design-icons/Magnify.vue'
import ViewList from 'vue-material-design-icons/ViewList.vue'
import {mapState} from 'vuex'
import KvTable from '@/components/KvTable.vue'

export default {
  components: {Magnify, KvTable, ViewList},
  data() {
    return {
      search: "",
      graph: undefined,
      loading: false,
      currentNoteLabel: "",
      colorMapping: {}
    }
  },
  mounted() {
    this.loading = true
    this.$store.dispatch('dataObject/graph').then(response => {
      this.graph = response.data
      setTimeout(this.drawGraph)
      console.log(this.graph)
      let colorIndex = 0;
      const colors = ["#2c98c1", "#c15b2c", "#c1a62c", "#8a2cc1", "#c74848", "#c78748", "#4a48c7", "#48c74c"]
      for (let node of this.graph.nodes) {
        if (!this.colorMapping[node.data_partner]) {
          this.colorMapping[node.data_partner] = colors[colorIndex]
          colorIndex++
          if (colorIndex > colors.length) {
            colorIndex = 0
          }
        }
      }
    })
  },
  computed: {
    ...mapState('dataObject', ['dataObject']),
    nodes() {
      return this?.graph?.nodes || []
    },
    dataObjectProperties() {
      return this.dataObject ? Object.entries(this.dataObject.object) : [];
    },
  },
  methods: {
    match(name) {
      if (!this.search || this.search.length < 3) {
        return false
      }
      return name.toLowerCase().includes(this.search.toLowerCase())
    },
    moveInfo(x, y, opacity) {
      const el = this.$el.querySelector('.link-info')
      el.style.left = x + 'px'
      el.style.top = y + 'px'
      el.style.opacity = opacity
      if (opacity === 0 && this.$el.querySelector('.link-node')) {
        this.$el.querySelector('.link-node').remove()
      }
    },
    modal(node) {
      console.log(node.id)
      this.$store.dispatch('dataObject/dataObject', node.id).then(response => {
        console.log('node', node, response)
        this.$bvModal.show('object-graph-modal')
      })
    },
    drawGraph() {
      const G = this.$el.querySelector('#graph')
      const w = G.offsetWidth
      // set the dimensions and margins of the graph
      const margin = {top: 10, right: 30, bottom: 30, left: 40};
      const width = w - margin.left - margin.right;
      const height = 800 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3.select("#graph")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

      const data = this.graph
      // Initialize the links
      const onClick = (event, link) => {
        this.currentNoteLabel = link.source.name + ' > ' + link.target.name
        this.moveInfo(event.x - G.getBoundingClientRect().left - 200, event.offsetY + 80, 1)
      }
      const link = svg
        .selectAll("line")
        .data(data.links)
        .enter()
        .append("line")
        .style("stroke", "#777")
        .on("mouseenter", (event, link) => {
          this.$el.querySelectorAll('.link-node').forEach(e => {
            e.removeEventListener("click", onClick)
            e.remove()
          })
          svg.append('circle')
            .attr('cx', event.x - G.getBoundingClientRect().left - 39)
            .attr('cy', event.y - G.getBoundingClientRect().top - 10)
            .attr('r', '6px')
            .attr('class', 'link-node')
            .style('fill', '#123123');
          this.$el.querySelectorAll('.link-node').forEach(e => {
            e.addEventListener("click", () => {
              onClick(event, link)
            })
          })

        })

      // Initialize the nodes
      var node = svg
        .selectAll("circle")
        .data(data.nodes)
        .enter()
        .append((i) => {
          return this.$el.querySelector('.fo-' + i.id)
        })


      // Let's list the force we want to apply on the network
      d3.forceSimulation(data.nodes)                 // Force algorithm is applied to data.nodes
        .force("link", d3.forceLink()                               // This force provides links between nodes
          .id(function (d) {
            return d.id;
          })                     // This provides  the id of a node
          .links(data.links)                                    // and this the list of links
        )
        .force("charge", d3.forceManyBody().strength(-7000))         // This adds repulsion between nodes. Play with the -400 for the repulsion strength
        .force("center", d3.forceCenter(width / 2, height / 2))     // This force attracts nodes to the center of the svg area
        .on("end", () => {
          link
            .attr("x1", function (d) {
              return d.source.x;
            })
            .attr("y1", function (d) {
              return d.source.y;
            })
            .attr("x2", function (d) {
              return d.target.x;
            })
            .attr("y2", function (d) {
              return d.target.y;
            });

          node
            .attr("x", function (d) {
              return d.x - 100;
            })
            .attr("y", function (d) {
              return d.y - 10;
            });
          G.style.opacity = 1
          this.loading = false
        });

    }
  }
};
</script>

<style scoped>
#graph {
  width: 100%;
  height: 600px;
  opacity: 0;
}

.selected-data-object {
  background-color: #38bb3c !important;

}

.data-object {
  border: 1px solid #555;
  height: 33px;
  color: white;
  width: 100%;
  border-radius: 5px;
  opacity: 0.95;
}

.link-info {
  position: absolute;
  border: 1px solid #eee;
  background-color: #555;
  color: white;
  border-radius: 5px;
  padding: 4px 6px 4px 6px;
  opacity: 0;
  width: 400px;
  font-size: 0.8em;
  height: 50px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%)
}

.group-legend {
  width: 15px;
  height: 15px;
}

.legend-wrapper {
  position: absolute;
}
</style>

<template>
  <b-row>
    <b-col md="4">
      <b-card class="text-center" title="Data implementation">
        <hr/>
        <b-table-simple caption-top class="data-implementation-selection" hover responsive small>
          <b-tbody class="pointer">
            <b-tr @click="setStep('groupObject')">
              <b-td><b>Data object</b></b-td>
              <b-td>
                <span v-if="selection.groupObject">{{ selection.groupObject.label }}</span>
              </b-td>
            </b-tr>
            <b-tr @click="setStep('targetAttribute')">
              <b-td><b>Target attribute</b></b-td>
              <b-td>
                  <span v-if="selection.targetAttribute">{{
                      selection.targetAttribute.code
                    }}</span></b-td>
            </b-tr>
            <b-tr @click="setStep('sourceDataPartner')">
              <b-td><b>Source data partner</b></b-td>
              <b-td>
                  <span v-if="selection.sourceDataPartner">{{
                      selection.sourceDataPartner
                    }}</span></b-td>
            </b-tr>
            <b-tr @click="setStep('sourceTable')">
              <b-td><b>Source table</b></b-td>
              <b-td>
                <span v-if="selection.sourceTable">{{ selection.sourceTable.label }}</span>
              </b-td>
            </b-tr>
            <b-tr @click="setStep('sourceAttribute')">
              <b-td><b>Source attribute</b></b-td>
              <b-td>
                  <span v-if="selection.sourceAttribute">{{
                      selection.sourceAttribute.code
                    }}</span></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-card-footer>
          <b-button :disabled="isValidDataImplementation" @click="createDataImplementation">
            <plus/>
            Create
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-col md="8">
      <b-card>
        <div v-if="currentStep === 'groupObject'">
          <h5>Select a group object</h5>
          <b-input v-model="search" placeholder="Search group object" type="search"/>
          <b-list-group class="mt-3">
            <b-list-group-item v-for="groupObject in groupObjects" :key="groupObject.id"
                               class="pointer"
                               @click="selectGroupObject(groupObject)">
              {{ groupObject.label }}
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="currentStep === 'targetAttribute'">
          <h5>Select a target attribute</h5>
          <b-list-group>
            <b-list-group-item v-for="attribute in attributes" :key="attribute.id"
                               class="pointer"
                               @click="selectTargetAttribute(attribute)">
              <b>{{ attribute.code }}</b> |
              <i>{{ attribute.label }} </i>
              <b-badge>{{ attribute.type }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="currentStep === 'sourceDataPartner'">
          <h5>Select a source data partner</h5>
          <b-list-group>
            <b-list-group-item v-for="dataPartner in dataPartnersNotGroup" :key="dataPartner"
                               class="pointer"
                               @click="selectSourceDataPartner(dataPartner)">
              {{ dataPartner }}
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="currentStep === 'sourceTable'">
          <h5>Select a source table</h5>
          <b-list-group>
            <b-list-group-item v-for="table in dataPartnerTables" :key="table.id"
                               class="pointer"
                               @click="selectSourceTable(table)">
              {{ table.label }}
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="currentStep === 'sourceAttribute'">
          <h5>Select a source attribute</h5>
          <b-list-group>
            <b-list-group-item v-for="attribute in attributes" :key="attribute.id"
                               class="pointer"
                               @click="selectSourceAttribute(attribute)">
              <b>{{ attribute.code }} </b>
              <b-badge>{{ attribute.type }}</b-badge>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div v-if="currentStep === 'end'">
          <h5>All ok !</h5>
          <p>Data implementation ready for creation</p>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from 'vuex'
import Plus from 'vue-material-design-icons/Plus.vue'
import Vue from 'vue';

export default {
  components: {Plus},
  data() {
    return {
      currentStep: 'groupObject',
      selection: {
        groupObject: undefined,
        targetAttribute: undefined,
        sourceDataPartner: undefined,
        sourceTable: undefined,
        sourceAttribute: undefined,
      },
      search: ""
    }
  },
  created() {
    this.$store.dispatch('dataObject/dataObjectAll')
    this.$store.dispatch('dataObject/filters')
  },
  computed: {
    ...mapState('dataObject', ['dataObjects', 'attributes', 'filters', 'dataPartnerTables']),
    dataPartnersNotGroup() {
      const dataPartners = []
      for (let dataPartner in this.filters) {
        if (!this.filters[dataPartner].includes("ALL")) {
          dataPartners.push(dataPartner)
        }
      }
      return dataPartners
    },
    isValidDataImplementation() {
      return !(
        this.selection.groupObject &&
        this.selection.targetAttribute &&
        this.selection.sourceDataPartner &&
        this.selection.sourceTable &&
        this.selection.sourceAttribute
      )
    },
    groupObjects() {
      let results = this.dataObjects.filter(item => item.country === 'ALL')
      if (this.search) {
        const searchLower = this.search.toLowerCase()
        results = results.filter(item => item.label.toLowerCase().includes(searchLower))
      }
      return results
    }
  },
  methods: {
    setStep(step) {
      this.currentStep = step
      this.resetItems({
        groupObject: ['groupObject', 'targetAttribute', 'sourceDataPartner', 'sourceTable', 'sourceAttribute'],
        targetAttribute: ['targetAttribute', 'sourceDataPartner', 'sourceTable', 'sourceAttribute'],
        sourceDataPartner: ['sourceDataPartner', 'sourceTable', 'sourceAttribute'],
        sourceTable: ['sourceTable', 'sourceAttribute'],
        sourceAttribute: ['sourceAttribute']
      }[step])
    },
    createDataImplementation() {
      const payload = {
        do_trg_id: this.selection.groupObject.id,
        doa_trg_id: this.selection.targetAttribute.id,
        do_src_id: this.selection.sourceTable.id,
        doa_src_id: this.selection.sourceAttribute.id,
      }
      this.$store.dispatch('dataObject/createImplementation', payload).then(() => {
        this.resetItems(['groupObject', 'targetAttribute', 'sourceDataPartner', 'sourceTable', 'sourceAttribute'])
        this.$bvToast.toast('Data implementation created', {
          title: 'Success',
          variant: 'success'
        })
        this.currentStep = 'groupObject'
      })
    },
    selectGroupObject(groupObject) {
      this.selection.groupObject = groupObject
      this.nextStep()
    },
    selectTargetAttribute(attribute) {
      this.selection.targetAttribute = attribute
      this.nextStep()
    },
    selectSourceDataPartner(dataPartner) {
      this.selection.sourceDataPartner = dataPartner
      this.nextStep()
    },
    selectSourceTable(table) {
      this.selection.sourceTable = table
      this.nextStep()
    },
    selectSourceAttribute(attribute) {
      this.selection.sourceAttribute = attribute
      this.nextStep()
    },
    resetItems(items) {
      for (let item of items) {
        Vue.set(this.selection, item, undefined)
      }
    },
    nextStep() {
      if (this.currentStep === 'groupObject') {
        this.currentStep = 'targetAttribute'
        this.$store.dispatch('dataObject/attributes', this.selection.groupObject.id)
      } else if (this.currentStep === 'targetAttribute') {
        this.currentStep = 'sourceDataPartner'
      } else if (this.currentStep === 'sourceDataPartner') {
        this.currentStep = 'sourceTable'
        this.$store.dispatch('dataObject/dataPartnerTables', this.selection.sourceDataPartner)
      } else if (this.currentStep === 'sourceTable') {
        this.$store.dispatch('dataObject/attributes', this.selection.sourceTable.id)
        this.currentStep = 'sourceAttribute'
      } else if (this.currentStep === 'sourceAttribute') {
        this.currentStep = 'end'
      }
    }
  }
};
</script>
<style scoped type="css">
.data-implementation-selection td {
  width: 50%;
}
</style>

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    dataObjectAttributes: [],
    dataObjectAttributeDetails: undefined,
  },
  actions: {
    search({ commit }) {
      Vue.axios.get("/data_object_attribute/search").then((data) => {
        commit("setDataObjectAttributes", data.data.data);
      });
    },
    one({ commit }, id) {
      Vue.axios.get("/data_object_attribute/" + id).then((data) => {
        commit("setDataObjectAttributeDetails", data.data);
      });
    },
    downloadCsv(_, dataObjectId) {
      return Vue.axios.get(`/data_object_attribute/data_object/${dataObjectId}/csv`);
    },
  },
  mutations: {
    setDataObjectAttributes(state, value) {
      state.dataObjectAttributes = value;
    },
    setDataObjectAttributeDetails(state, value) {
      state.dataObjectAttributeDetails = value;
    },
  },
  getters: {},
};

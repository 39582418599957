<template>
  <b-row>
    <b-col cols="12" lg="8" md="8" offset="0" offset-lg="2" offset-xl="3" xl="6">
      <b-input v-model="search" class="mt-2" placeholder="Search for DO | DOA ..." size="lg"
               type="search" @input="onSearch"></b-input>
      <div class="results-wrapper mt-4 pb-2">
        <b-card v-for="(result, x) in searchResults"
                :key="`${result.id}-${result.type}`"
                class="mt-2">
          <template v-if="result.type === 'do'">
            <b-badge :id="`popover-search-${x}`" class="mr-2 ask-cursor" variant="primary">Obj
            </b-badge>
            {{ result.label }}
            <router-link
              :to="{name: 'data-object', params:{id: result.id}, query: {tab: 'details'} }">
              <b-button :e2e-id="`obj-${result.id}`" class="float-right" size="sm">
                <eye/>
              </b-button>
            </router-link>
          </template>
          <template v-if="result.type === 'doa'">
            <b-badge :id="`popover-search-${x}`" class="mr-2 ask-cursor" variant="warning">Attr
            </b-badge>
            {{ result.label }}
            <router-link
              :to="{name: 'data-object', params:{id: result.do_id}, query:{tab: 'attributes', details: result.id }}">
              <b-button :e2e-id="`attr-${result.id}`" class="float-right" size="sm">
                <eye/>
              </b-button>
            </router-link>
          </template>
          <b-popover :target="`popover-search-${x}`" placement="left" triggers="hover">
            <kv-table :kvs="Object.entries(result.metas)"/>
          </b-popover>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Eye from 'vue-material-design-icons/Eye.vue'
import KvTable from '@/components/KvTable.vue'

import {mapState} from 'vuex'

export default {
  components: {Eye, KvTable},
  data() {
    return {
      search: ""
    }
  },
  computed: {
    ...mapState('dataObject', ['searchResults']),
  },
  methods: {
    onSearch() {
      if (this.search.length > 3) {
        this.$store.dispatch('dataObject/search', this.search)
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('dataObject/setSearchResults', [])
  }
};
</script>
<style scoped>
input {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.list-group-item:hover {
  background-color: #fafafa;
}

.results-wrapper {
  overflow-y: auto;
  height: calc(100vh - 130px);
}

.card-body {
  padding-top: 10px;
  padding-bottom: 5px;
}
</style>


<template>
  <b-card bg-variant="light" class="left-menu">
    <router-link to="/search">
      <img alt="app logo" class="logo" src="../assets/logo.png">
    </router-link>
    <br/>
    <br/>
    <b-row>
      <b-col>
        <b-list-group v-if="user">
          <b-list-group-item class="mt-2">
            <router-link to="/search">Search</router-link>
          </b-list-group-item>
          <b-list-group-item class="mt-2">
            <router-link to="/data-object-list">Data objects</router-link>
          </b-list-group-item>
          <b-list-group-item class="mt-2">
            <router-link to="/data-implementation">Implementations</router-link>
          </b-list-group-item>
          <b-list-group-item class="mt-2">
            <router-link to="/graph">Data Explorer</router-link>
          </b-list-group-item>
          <b-list-group-item class="pointer mt-2" @click="logout">
            <LogoutVariant/>
            Logout
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import LogoutVariant from 'vue-material-design-icons/LogoutVariant.vue';
import {mapState} from 'vuex'

export default {
  components: {
    LogoutVariant
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
  computed: {
    ...mapState('auth', ['user'])
  }

};
</script>

<style scoped>

.left-menu {
  text-align: center;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 0 5px 5px 0;
  height: calc(100vh - 20px);
}

.logo {
  width: 100%;
  border-radius: 0 5px 0 0;
}

.list-group-item {
  box-shadow: 3px 3px 3px #eee;
}
</style>

<template>
  <b-modal size="lg" id="create-data-object" title="Create data object" @ok="onSubmit"
           :ok-disabled="!validForm">
    <b-form>
      <b-form-group label="Code">
        <b-form-input
          v-model="form.code"
          type="text"
          placeholder="Code"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Label">
        <b-form-input
          v-model="form.label"
          type="text"
          placeholder="Label"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Type">
        <b-form-select v-model="form.type" :options="typeOptions"></b-form-select>
      </b-form-group>
      <b-form-group label="Definition">
        <b-form-input
          v-model="form.definition"
          type="text"
          placeholder="Definition"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Comments">
        <b-form-input
          v-model="form.comments"
          type="text"
          placeholder="Comments"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Data partner">
        <b-form-select v-if="filters" v-model="form.data_partner"
                       :options="dataPartners"></b-form-select>
      </b-form-group>
      <b-form-group label="Country">
        <b-form-select :disabled="countries.length === 0" v-if="filters" v-model="form.country"
                       :options="countries"></b-form-select>
      </b-form-group>
      <b-form-group label="State">
        <b-form-select v-model="form.state"
                       :options="['draft', 'validate']"></b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      form: {
        code: "",
        label: "",
        type: "",
        definition: "",
        comments: "",
        data_partner: "",
        country: "",
        state: "",
      },
      typeOptions: [
        {value: "TABLE", text: "table"},
        {value: "VIEW", text: "view"},
        {value: "MATERIALIZED VIEW", text: "materialized view"},
        {value: "FILE", text: "file"},
      ]
    }
  },
  props: {
    data_object: {
      type: Object,
      default: undefined
    }
  },

  created() {
    this.$store.dispatch('dataObject/filters')
    if (this.data_object) {
      this.form = JSON.parse(JSON.stringify(this.data_object))
    }
  },
  computed: {
    ...mapState('dataObject', ['groupObjects', 'filters']),
    dataPartners() {
      return Object.keys(this.filters)
    },
    countries() {
      return this.form.data_partner ? this.filters[this.form.data_partner] : []
    },
    validForm() {
      return (
        this.form.code &&
        this.form.label &&
        this.form.type &&
        this.form.definition &&
        this.form.comments &&
        this.form.data_partner &&
        this.form.country &&
        this.form.state
      )
    }
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit', this.form)
    }
  }
};
</script>

<style scoped>
</style>

<template>
  <b-card>
    <b-row>
      <b-col class="tree-section" md="3">
        <p class="text-center font-weight-bold">Select a group object</p>
        <b-input v-model="search" placeholder="Search group object" type="search"/>
        <b-list-group class="mt-2 group-object-wrapper">
          <b-list-group-item
            v-for="groupObject in groupObjects"
            :key="groupObject.id"
            :class="{active: groupObject.id === (selectedGroupObject && selectedGroupObject.id)}"
            class="pointer" @click="selectGroupObject(groupObject)">{{ groupObject.label }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col class="tree-section" md="3">
        <p class="text-center font-weight-bold">Select a source table</p>
        <b-list-group v-if="sources">
          <b-list-group-item
            v-for="source in sources"
            :key="source.id"
            :class="{active: (selectedSource && selectedSource.id) === (source && source.id)}"
            class="pointer" @click="selectSource(source)">{{ source.label }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="6">
        <p class="text-center font-weight-bold">Source attributes</p>
        <b-list-group v-if="implementations">
          <template v-for="implementation in implementations">
            <b-list-group-item
              :key="`attribute-item-${implementation.target_id}`"
              :class="{fade: !implementation.source_id}" class="pointer">
              {{ implementation.target }}
              <b-badge>{{ implementation.target_type }}</b-badge>
              <template v-if="implementation.source">
                <arrow-left class="mr-2 ml-2"/>
                <span class="text-info mr-2">{{ implementation.source }}</span>
                <b-badge variant="info">{{ implementation.source_type }}</b-badge>
              </template>
            </b-list-group-item>
          </template>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {mapState} from 'vuex'
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'

export default {
  components: {ArrowLeft},
  data() {
    return {
      selectedGroupObject: undefined,
      selectedSource: undefined,
      search: "",
    }
  },
  created() {
    this.$store.dispatch('dataObject/dataObjectAll')
  },
  computed: {
    ...mapState('dataObject', ['dataObjects', 'sources', 'attributes', 'dataObject', 'implementations']),
    groupObjects() {
      let results = this.dataObjects.filter(item => item.country === 'ALL')
      if (this.search) {
        const searchLowerCase = this.search.toLowerCase()
        results = results.filter(item => item.label.toLowerCase().includes(searchLowerCase))
      }
      return results
    },
  },
  methods: {
    selectGroupObject(groupObject) {
      this.$store.commit('dataObject/setSources', [])
      this.$store.commit('dataObject/setDataObjectAttributes', undefined)
      this.selectedGroupObject = groupObject
      this.$store.dispatch('dataObject/dataObject', this.selectedGroupObject.id)
      this.$store.dispatch('dataObject/sources', this.selectedGroupObject.id)
      this.$store.commit('dataObject/setImplementations', [])
    },
    selectSource(source) {
      this.selectedSource = source
      this.$store.dispatch('dataObject/implementations', {
        dataObjectId: this.selectedGroupObject.id,
        sourceId: this.selectedSource.id
      })
    }
  }
};
</script>
<style scoped>
.tree-section {
  border-right: 1px solid #888;
}

.fade {
  opacity: 0.3;
}

.group-object-wrapper {
  overflow: auto;
  height: calc(100vh - 300px);
}
</style>

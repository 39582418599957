import Vue from "vue";
import Vuex from "vuex";
import core from "./core";
import settings from "./settings";
import auth from "./auth";
import dataObject from "./dataObject";
import dataObjectAttribute from "./dataObjectAttribute";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    core,
    auth,
    settings,
    dataObject,
    dataObjectAttribute,
  },
});

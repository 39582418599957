<template>
  <div id="app" class="container-fluid">
    <b-row>
      <b-col md="2">
        <left-menu class="mt-2 mb-2"/>
      </b-col>
      <b-col md="10">
        <b-card class="mt-2 content">
          <router-view/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import {mapState} from 'vuex'

export default {
  name: 'App',
  components: {LeftMenu},
  async created() {
    if (!localStorage.getItem('token')) {
      if (this.$route.name !== 'login') {
        window.location = '/login'
      }
    } else {
      this.$store.dispatch('auth/profile').catch(response => {
        if (response.response.status === 403) {
          localStorage.removeItem('token')
        }
      }).finally(() => {
        if (!localStorage.getItem('token') && this.$route.name !== 'login') {
          window.location = '/login'
        }
      })
    }
  },
  computed: {
    ...mapState('auth', ['token'])
  }
}
</script>

<style>
#app {
  overflow-x: hidden;
}

.content {
  height: calc(100vh - 20px);
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}

body.modal-open {
  overflow: inherit;
  padding-right: 0 !important;
}

.ask-cursor{
  cursor: help;
}
</style>

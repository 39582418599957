<template>
  <b-row>
    <b-col cols="2">
      <b-dropdown :text="dataPartnerTitle" right>
        <b-dropdown-item v-for="dataPartner in dataPartners"
                         :key="dataPartner" @click="selectDataPartner(dataPartner)">
          {{ dataPartner }}
        </b-dropdown-item>
        <b-dropdown-item @click="selectDataPartner()"> -</b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="4">
      <b-input placeholder="Search in data objects" type="search" v-model="search"/>
    </b-col>
    <b-col cols="6">
      <b-btn v-b-modal.create-data-object class="mb-2 float-right" variant="primary">
        <PlusBox/>
        Create
      </b-btn>
    </b-col>
    <b-col class="list-wrapper" cols="12">
      <data-object-modal @onSubmit="onSubmit"/>
      <b-list-group>
        <b-list-group-item v-for="dataObject in dataObjectsFiltered" :key="dataObject.id"
                           class="pointer text-primary"
                           @click="showDetails(dataObject.id)">
          {{ dataObject.label }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from 'vuex'
import PlusBox from 'vue-material-design-icons/PlusBox.vue'
import DataObjectModal from './DataObjectModal.vue'

export default {
  components: {PlusBox, DataObjectModal},
  data() {
    return {
      selectedDataPartner: undefined,
      search: ""
    }
  },
  created() {
    this.$store.dispatch('dataObject/dataObjectAll')
  },
  computed: {
    ...mapState('dataObject', ['dataObjects', 'filters']),
    dataObjectsFiltered() {
      let selection;
      if (this.selectedDataPartner) {
        selection = this.dataObjects.filter(item => item.data_partner === this.selectedDataPartner)
      } else {
        selection = this.dataObjects
      }
      if (this.search) {
        const lcSearch = this.search.toLowerCase()
        selection = selection.filter(item => item.label.toLowerCase().includes(lcSearch))
      }
      return selection
    },
    dataPartners() {
      return Object.keys(this.filters || {})
    },
    dataPartnerTitle() {
      return this.selectedDataPartner || "Data partner"
    },
  },
  methods: {
    showDetails(id) {
      this.$router.push({name: 'data-object', params: {id}})
      this.$store.dispatch('dataObject/dataObject', id).then(()=>{
        this.$emit('onSelectTab', 'details')
      })
    },
    onSubmit(form) {
      this.$store.dispatch('dataObject/create', form).then(() => {
        this.$store.dispatch('dataObject/dataObjectAll')
        this.$bvToast.toast('Data object created', {
          title: 'Success',
          variant: 'success'
        })
      })
    },
    selectDataPartner(dataPartner) {
      this.selectedDataPartner = dataPartner
    },

  }
};
</script>

<style scoped>
.list-wrapper {
  overflow-y: auto;
  height: calc(100vh - 220px);
}

</style>

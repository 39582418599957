import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["user"]),
    isAdmin() {
      return this.user?.role === "admin";
    },
  },
};

<template>
  <b-row>
    <b-col cols="12" offset="0" md="4" offset-md="4">
      <b-form class="mt-5">
        <b-form-group label="Email">
          <b-form-input
            v-model="form.email"
            type="text"
            placeholder="Email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mt-3" label="Password">
          <b-form-input
            v-model="form.password"
            type="password"
            placeholder="Password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="text-center">
          <b-button class="mt-4" @click="onSubmit">Login</b-button>
        </b-form-group>
        <ul class="mt-3 text-danger" v-if="errors">
          <li v-for="(error, x) in errors" :key="x">{{error.msg}}</li>
        </ul>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        password: "",
        email: "",
      },
      errors: undefined
    }
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$store.dispatch('auth/login', this.form).catch(error => {
        this.errors = error.response.data.detail
      })
    }
  }
};
</script>


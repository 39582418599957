<template>
  <div>
    <h4>Data Object</h4>
    <hr/>
    <div>
      <b-tabs content-class="mt-3" lazy>
        <b-tab :active="selectedTab === 'list'" title="List" @click="selectTab('list')">
          <data-object-list @onSelectTab="selectTab"/>
        </b-tab>
        <b-tab v-if="dataObject" :active="selectedTab === 'details'" title="Details"
               @click="selectTab('details')">
          <b-row v-if="dataObject">
            <b-col md="6" offset="3">
              <b-card>
                <kv-table :kvs="dataObjectProperties"/>
                <b-card-footer class="text-center">
                  <b-button v-b-modal.create-data-object>
                    Edit
                    <pencil/>
                  </b-button>
                </b-card-footer>
              </b-card>
              <data-object-modal :data_object="dataObject.object" @onSubmit="onSubmit"/>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab v-if="dataObject" :active="selectedTab === 'attributes'" title="Attributes"
               @click="selectTab('attributes')">
          <b-row>
            <b-col md="10" offset-md="1">
              <b-button v-b-tooltip="'download as csv'" class="float-right"
                        @click="downloadCsv(dataObject.object)">
                <download/>
                CSV
              </b-button>
            </b-col>
            <b-col md="10" offset-md="1">
              <b-card v-if="dataObject" class="mt-2">
                <b-table-simple class="text-center" hover responsive>
                  <b-thead>
                    <b-tr>
                      <b-th>code</b-th>
                      <b-th>label</b-th>
                      <b-th>type</b-th>
                      <b-th>country</b-th>
                      <b-th>state</b-th>
                      <b-th>position</b-th>
                      <b-th>action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-for="attribute in dataObject.attributes">
                      <b-tr :key="`attribute-${attribute.id}`">
                        <b-td>{{ attribute.code }}</b-td>
                        <b-td>{{ attribute.label }}</b-td>
                        <b-td>{{ attribute.type }}</b-td>
                        <b-td>{{ dataObject.object.country }}</b-td>
                        <b-td>{{ attribute.state }}</b-td>
                        <b-td>{{ attribute.position }}</b-td>
                        <b-td>
                          <b-button class="mr-2" size="sm" @click="showDetails(attribute)">Details
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal id="attributes-modal" :hide-footer="true">
      <kv-table v-if="selectedDetailAttribute" :exclude="['data_object']"
                :kvs="Object.entries(selectedDetailAttribute)"/>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import KvTable from '@/components/KvTable.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import Download from 'vue-material-design-icons/Download.vue'
import DataObjectModal from './DataObjectModal.vue'
import DataObjectList from './DataObjectList.vue'

export default {
  components: {KvTable, Pencil, DataObjectModal, Download, DataObjectList},
  data() {
    return {
      selectedTab: "list",
      selectedDetailAttribute: undefined
    }
  },
  created() {
    const tab = this.$route.query.tab
    if (tab && tab !== 'list') {
      this.$store.dispatch('dataObject/dataObject', this.$route.params.id).then(() => {
        if (this.$route.query.tab) {
          this.selectedTab = this.$route.query.tab
        }
        if (this.$route.query.tab === 'attributes' && this.$route.query.details) {
          const attributeLine = this.dataObject.attributes.find(a => a.id === parseInt(this.$route.query.details))
          this.showDetails(attributeLine)
        }
      })
    }
  },
  methods: {
    selectTab(tab) {
      console.log('on tab', tab)
      this.selectedTab = tab
      this.$router.push({query: {tab}})
    },
    downloadCsv(dataObject) {
      this.$store.dispatch('dataObjectAttribute/downloadCsv', dataObject.id).then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${dataObject.label}-attributes.csv`;
        link.click();
      })
    },
    showDetails(lineAttribute) {
      this.selectedDetailAttribute = lineAttribute
      this.$bvModal.show('attributes-modal')
    },
    onSubmit(form) {
      this.$store.dispatch('dataObject/update', form).then(() => {
        this.$store.dispatch('dataObject/dataObject', this.$route.params.id)
        this.$bvToast.toast('Data implementation updated', {
          title: 'Success',
          variant: 'success'
        })
      })
    }
  },
  computed: {
    ...mapState('dataObject', ['dataObject']),
    dataObjectProperties() {
      return Object.entries(this.dataObject.object)
    },
  },
  beforeDestroy() {
    this.$store.commit('dataObject/setDataObject', undefined)
  }
};
</script>

<style scoped>
</style>

<template>
  <b-table-simple hover small caption-top responsive>
    <b-tbody>
      <b-tr v-for="(attribute, x) in filteredKvs" :key="x">
        <b-td class="text-center"><b>{{ attribute[0] }}</b></b-td>
        <b-td>{{ attribute[1] }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  props: {
    kvs: {
      type: Array,
      required: true
    },
    exclude:{
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredKvs() {
      return this.kvs.filter(item => item[0] !== 'id' && !this.exclude.includes(item[0]))
    }
  }
};
</script>

<style scoped>
td, th {
  padding: 8px;
}
</style>

import LoginUser from "./components/LoginUser.vue";
import DataObject from "./components/DataObject.vue";
import DataImplementation from "./components/DataImplementation.vue";
import HomeSearch from "./components/HomeSearch.vue";
import DataObjectGraph from "./components/DataObjectGraph.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: "/",
  routes: [
    { name: "login", path: "/login", component: LoginUser },
    {
      name: "data-object",
      path: "/data-object/:id",
      component: DataObject,
    },
    {
      name: "data-object-list",
      path: "/data-object-list",
      component: DataObject,
    },
    {
      name: "data-implementation",
      path: "/data-implementation",
      component: DataImplementation,
    },
    {
      name: "search",
      path: "/search",
      component: HomeSearch,
    },
    {
      name: "home-search",
      path: "/",
      component: HomeSearch,
    },
    {
      name: "graph",
      path: "/graph",
      component: DataObjectGraph,
    },
  ],
});

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    dataObjects: [],
    dataObject: undefined,
    filters: undefined,
    attributes: undefined,
    dataPartnerTables: undefined,
    sources: [],
    implementations: undefined,
    searchResults: [],
  },
  actions: {
    dataObjectAll({ commit }) {
      Vue.axios.get("/data_object").then((data) => {
        commit("setDataObjects", data.data.data);
      });
    },
    dataObject({ commit }, id) {
      return Vue.axios.get(`/data_object/${id}`).then((data) => {
        commit("setDataObject", data.data);
      });
    },
    filters({ commit }) {
      Vue.axios.get("/data_object/filters").then((data) => {
        commit("setDataObjectFilters", data.data.data);
      });
    },
    create(_, payload) {
      return Vue.axios.post("/data_object", payload);
    },
    update(_, payload) {
      return Vue.axios.put(`/data_object/${payload.id}`, payload);
    },
    attributes({ commit }, dataObjectId) {
      return Vue.axios.get(`/data_object/${dataObjectId}`).then((data) => {
        commit("setDataObjectAttributes", data.data.attributes);
      });
    },
    dataPartnerTables({ commit }, dataPartner) {
      return Vue.axios.get(`/data_object/${dataPartner}/tables`).then((data) => {
        commit("setDataPartnerTables", data.data.data);
      });
    },
    createImplementation(_, payload) {
      return Vue.axios.post("/data_implementation", payload);
    },
    sources({ commit }, dataObjectId) {
      return Vue.axios.get(`/data_object/sources/${dataObjectId}`, dataObjectId).then((data) => {
        commit("setSources", data.data.data);
      });
    },
    implementations({ commit }, payload) {
      return Vue.axios
        .get(`/data_implementation/target/${payload.dataObjectId}/source/${payload.sourceId}/diff`)
        .then((data) => {
          commit("setImplementations", data.data.data);
        });
    },
    graph() {
      return Vue.axios.get(`/data_implementation/graph`);
    },
    search({ commit }, search) {
      return Vue.axios.get(`/data_object/search`, { params: { search } }).then((response) => {
        commit("setSearchResults", response.data.data);
      });
    },
  },
  mutations: {
    setDataObjects(state, value) {
      state.dataObjects = value;
    },
    setDataObject(state, value) {
      state.dataObject = value;
    },
    setDataObjectFilters(state, value) {
      state.filters = value;
    },
    setDataObjectAttributes(state, value) {
      state.attributes = value;
    },
    setDataPartnerTables(state, value) {
      state.dataPartnerTables = value;
    },
    setSources(state, value) {
      state.sources = value;
    },
    setImplementations(state, value) {
      state.implementations = value;
    },
    setSearchResults(state, value) {
      state.searchResults = value;
    },
  },
  getters: {},
};
